import * as React from 'react';
import classnames from 'classnames';
import { ISectionProps } from '../Section.types';
import { TestIds } from '../constants';
import BackgroundX from '../../../BackgroundX/viewer/BackgroundX';
import { getDataAttributes } from '../../../../core/commons/utils';
import ShapeDividers from '../../../MediaContainers/ShapeDividers/viewer/shapeDividers';
import style from './styles/Section.scss';

export type SectionProps = ISectionProps & {
  className?: string;
};

const Section: React.FC<SectionProps> = props => {
  const {
    id,
    skin = 'RectangleArea',
    className = style[skin],
    containerRootClassName = '',
    containerProps,
    children,
    tagName,
    background,
    getPlaceholder,
    dividers,
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const TagName = tagName || ('section' as keyof JSX.IntrinsicElements);
  const { shouldOmitWrapperLayers } = containerProps;
  const classNames = classnames(className, containerRootClassName, {
    [style.shouldOmitWrapperLayers]: shouldOmitWrapperLayers,
  });
  return (
    <TagName
      id={id}
      {...getDataAttributes(props)}
      className={classNames}
      data-testid={TestIds.section}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {background && (
        <BackgroundX {...background} getPlaceholder={getPlaceholder} />
      )}
      {dividers && <ShapeDividers {...dividers} />}
      {
        children() // contains: (1) ResponsiveContainer with relative children, (2) DynamicStructureContainer with pinned children
      }
    </TagName>
  );
};

export default Section;
